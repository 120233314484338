* {
  font-family: Satoshi-Medium, sans-serif;
  font-weight: 500;
}

.webPage {
  padding: 0 !important;
  margin: 0 !important;
  background-color: #152136 !important;
}

p {
  display: inline !important;
}

.whiteText {
  color: #ffffff;
}

.darkBlueText {
  color: #152136;
}

.blackText {
  color: #000000;
}

.blueText {
  color: #3d6dd8;
}

.yellowText {
  color: #eebb72;
}

.blueBackground {
  background-color: #152136;
}

.greyBackground {
  background-color: #e9eef7;
}

@media screen and (min-width: 1366px) {
  .navLink {
    font-size: 1.25vw;
    line-height: 133%;
  }

  .bodyCopy1 {
    font-size: 1.389vw;
    line-height: 140%;
  }

  .bodyCopy2 {
    font-size: 1.25vw;
    line-height: 140%;
  }

  .heading1 {
    font-size: 3.750vw;
    line-height: 135%;
  }

  .heading2 {
    font-size: 3.472vw;
    line-height: 126%;
  }

  .heading3 {
    font-size: 2.778vw;
    line-height: 135%;
  }

  .heading4 {
    font-size: 2.083vw;
    line-height: 150%;
    letter-spacing: 1px;
  }

  .heading5 {
    font-size: 1.25vw;
    line-height: 160%;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .number {
    font-size: 2.292vw;
    line-height: 135%;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .minorLink {
    font-size: 1.111vw;
    line-height: 135%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1365px) {
  .navLink {
    font-size: 2.25vw;
    line-height: 133%;
  }

  .bodyCopy1 {
    font-size: 2.389vw;
    line-height: 140%;
  }

  .bodyCopy2 {
    font-size: 2.25vw;
    line-height: 140%;
  }

  .heading1 {
    font-size: 4.750vw;
    line-height: 135%;
  }

  .heading2 {
    font-size: 4.472vw;
    line-height: 126%;
  }

  .heading3 {
    font-size: 3.778vw;
    line-height: 135%;
  }

  .heading4 {
    font-size: 3.083vw;
    line-height: 150%;
    letter-spacing: 1px;
  }

  .heading5 {
    font-size: 2.25vw;
    line-height: 160%;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .number {
    font-size: 3.292vw;
    line-height: 135%;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .minorLink {
    font-size: 2.111vw;
    line-height: 135%;
  }
}

@media screen and (max-width: 767px) {
  .navLink {
    font-size: 3.25vw;
    line-height: 133%;
  }

  .bodyCopy1 {
    font-size: 3.389vw;
    line-height: 140%;
  }

  .bodyCopy2 {
    font-size: 3.25vw;
    line-height: 140%;
  }

  .heading1 {
    font-size: 5.750vw;
    line-height: 135%;
  }

  .heading2 {
    font-size: 5.472vw;
    line-height: 126%;
  }

  .heading3 {
    font-size: 4.778vw;
    line-height: 135%;
  }

  .heading4 {
    font-size: 4.083vw;
    line-height: 150%;
    letter-spacing: 1px;
  }

  .heading5 {
    font-size: 3.25vw;
    line-height: 160%;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .number {
    font-size: 4.292vw;
    line-height: 135%;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .minorLink {
    font-size: 3.111vw;
    line-height: 135%;
  }
}
