.hadokenTitle {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.hadokenHeading {
  color: #263245;
}

.hadokenCaption {
  padding-left: 10%;
  padding-right: 10%;
}

@media screen and (min-width: 1366px) {
  .hadokenHeading {
    font-size: 10vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1365px) {
  .hadokenHeading {
    font-size: 15vw;
  }
}

@media screen and (max-width: 767px) {
  .hadokenHeading {
    font-size: 15vw;
  }
}

@media screen and (max-width: 900px) {
  .hadokenTitle {
    min-height: 100vw;
  }
}
