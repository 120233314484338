.hadokenParticles {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width: 900px) {
  .hadokenParticles {
    min-height: 100vw;
  }
}
